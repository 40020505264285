import React, { Component, createRef } from 'react'

import styled from 'styled-components'

import Layout from '../components/layout'

import { MainSectionWrapper } from '../components/common/styles'

const MainSectionWrapperOverride = styled(MainSectionWrapper)`
    max-height: 550px;
`;

const ValentinaHeadshot = styled.img`
    max-width: 45%;
    max-height: 375px;
    display: block;
    margin: 50px auto;
    padding: 15px;
}
`

class About extends Component {
    scrollRef = createRef()

    componentDidMount = () => {
        window.addEventListener('keydown', this.scroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener('keydown', this.scroll)
    }

    scroll = (e) => {
        switch (e.key) {
            case "ArrowDown":
                this.scrollRef.current.scrollBy(0, 30)
                break
            case "ArrowUp":
                this.scrollRef.current.scrollBy(0, -30)
                break
        }
    }

    render() {
        return (
            <Layout>
                <MainSectionWrapperOverride ref={this.scrollRef}>
                    <div style={{ width: '70%', margin: '0 auto', textAlign: 'justify', }}>
                        <ValentinaHeadshot
                            src="https://s3.amazonaws.com/valentina-site/valentina_ramirez_headshot.png"
                        />
                        <p>
                            I’m a designer who is passionate about connecting culture and communities with practical design.
                            I have a Bachelor’s Degree in Fine Arts from Parsons School of Design in New York
                            and am pursuing a diploma in innovation and entrepreneurship at Stanford University.
                        </p>
                        <p>
                            My interest in interactive design started at an early age.
                            Back home as a teenager, I traveled and volunteered with different communities in my country and
                            gained an enthusiasm for traditional arts and crafts.
                            Once in college, I volunteered with organizations such as Cool Culture and Masa -
                            both community organizations that promote education.
                            My work in college mainly targeted practical collections or systems based on these experiences.
                        </p>
                        <p>
                            Following my graduation, I worked in Haiti as part of a partnership from Parsons School of Design
                            with Donna Karan’s Urban Zen foundation and Rihanna’s Clara Lionel Foundation.
                            After Haiti, I was eager to implement similar design systems in other communities.
                            I worked with the Pachamama community, a Matriarchal indigenous community that lives in the Ibambura region of Ecuador.
                            With Pachamama, a team and I developed workshops on sustainable materials, exchange ideas, design perspective and narrative.
                        </p>
                        <p>
                            My experience and work with artisan communities and manufacturers in Central and South America
                            have driven my interest in finding more accessible and automated solutions to education and sustainable problems.
                            With my current project ColorBot, a team of developers and I aim to
                            efficiently design color combinations and patterns as fabric template suggestions.
                            The idea of ColorBot is to decrease waste, optimize production in clustered garment factories and give individuals exposure to color theory.
                        </p>
                        <p>
                            I know our world has much knowledge to provide. In my spare time I love to dive and learn languages.
                            My inspiration and creativity come from immersing myself into different cultures around the world.
                            I have visited 46 countries to date.
                        </p>
                        <p>
                            <a target='_blank'style={{ textDecoration: 'underline' }} href='https://s3.amazonaws.com/valentina-site/cv.pdf'>
                                Extended CV can be found here.
                            </a>
                        </p>
                        <p>
                            Born in Bogota, Colombia.
                        </p>
                    </div>
                </MainSectionWrapperOverride>
            </Layout>
        )
    }
}

export default About